import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Filter } from '../models/filter';

import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { EventListService } from '../services/event-list.service';
import { ViewportScroller, formatDate, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Event } from '../models/event';
import { TransferState, Title, makeStateKey } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ScrollService } from '../services/scroll.service';
import { SeoService } from '../services/seo.service';
import { UtilsService } from '../services/utils.service';
import { CategoryService } from '../services/category.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { Category } from '../models/category';

const EVENT_LIST = makeStateKey('eventList');

@Component({
  selector: 'app-frontpage-events',
  templateUrl: './frontpage-events.component.html',
  styleUrls: ['./frontpage-events.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class FrontpageEventsComponent implements OnInit, OnDestroy {

  //VM FEST
  @Input()
  isVMFest: boolean;
  municipalityControl: FormControl = new FormControl('Trondheim'); // Default selected value
  municipalities = [
    'Åfjord',
    'Bindal',
    'Flatanger',
    'Frosta',
    'Frøya',
    'Grong',
    'Hitra',
    'Holtålen',
    'Indre Fosen',
    'Leka',
    'Levanger',
    'Lierne',
    'Malvik',
    'Melhus',
    'Meråker',
    'Midtre Gauldal',
    'Namdalseid',
    'Namsskogan',
    'Namsos',
    'Oppdal',
    'Orkland',
    'Os',
    'Overhalla',
    'Rennebu',
    'Rindal',
    'Røros',
    'Selbu',
    'Skaun',
    'Snåsa',
    'Steinkjer',
    'Stjørdal',
    'Trondheim',
    'Tydal',
    'Verdal',
    'Vikna',
    'Ørland'
  ];
  municipalitySubscription: Subscription;

  dateRangeForm: FormGroup = new FormGroup({
    fromDate: new FormControl(),
    untilDate: new FormControl(),
  });

  eventList: Event[];
  environment: any;
  loading: boolean = true;
  loadMoreIsLoading: boolean = false;
  scrollOnViewInit: boolean = false;
  title: string;
  totalHits: number;
  filter: Filter = new Filter();
  moveToPreviousScrollPosition: boolean = true;
  paramsSub: Subscription;
  dateFilterSubs: Subscription;
  eventListSubs: Subscription;
  subCategories: Category[];
  selectedSubCategory: Category;

  constructor(private seo: SeoService,
    private scrollService: ScrollService,
    private viewportScroller: ViewportScroller,
    public eventListService: EventListService,
    public authService: AuthService,
    private state: TransferState,
    private titleService: Title,
    public translate: TranslateService,
    private categoryService: CategoryService,
    @Inject(PLATFORM_ID) private platformId: any,
    private route: ActivatedRoute,
    private router: Router,
    private adapter: DateAdapter<any>,
    public utilsService: UtilsService) {
    this.environment = environment;
  }

  ngOnInit(): void {
    //Get notified when the event list changes
    this.initListSubscription();
    //Get notified when the loading status changes
    this.initLoadingSubscription();
    //Get notified when the load more events loading status changes
    this.initLoadMoreIsLoadingSubscription();
    //Setup the filter
    this.setupFilter();
    //Setup SEO information
    this.setupSEO();
    //Set locale to date filter
    this.initializeDateFilter();
    //Setup date filter
    this.setupDateFilter();
    //Setup municipality filter
    this.setupMunicipalityFilter();
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      this.scrollService.setLastPositionForEventList(this.viewportScroller.getScrollPosition()[1]);
    }
    this.paramsSub?.unsubscribe();
    this.dateFilterSubs?.unsubscribe();
    this.eventListSubs?.unsubscribe();
    this.municipalitySubscription?.unsubscribe();
  }

  setupSEO() {
    //Tags and JSON+LD
    this.seo.generateTags({ title: this.title });
    //Set the title of the page
    this.titleService.setTitle(this.utilsService.getTitleFrontpage() + ' | ' + environment.content.siteName);
  }

  setupFilter() {
    this.paramsSub = this.route.params.subscribe(
      (params) => {
        this.title = '';
        //Setup the filter according to the URL params
        const newFilter: Filter = new Filter();
        if (params.categories || params.fromDate || params.untilDate || params.searchTerm) {
          if (params.categories) {
            newFilter.categories = this.categoryService.getCategoryIDsForSuperSlug(params.categories);
            this.subCategories = this.categoryService.getSubcategoriesBySlug(params.categories);
            this.selectedSubCategory = this.categoryService.getCategoryBySlug(params.categories);
            if (params.categories == 'all-events' || params.categories == 'alle-events') {
              this.title += this.translate.instant("All events");
            } else {
              this.title += this.categoryService.getCategoryBySlug(params.categories)?.name + " ";
            }
          }
          if (params.searchTerm) {
            this.title += this.translate.instant("Search term") + ": " + params.searchTerm;
            newFilter.searchTerm = params.searchTerm;
          }
          if (this.parseDate(params.fromDate)) {
            this.title += " " + this.translate.instant("from") + " " + params.fromDate + " ";
            newFilter.fromDate = formatDate(moment(params.fromDate, 'DD/MM/YYYY').toDate(), "d.MM.yyyy", 'nb-NO');
          }
          if (this.parseDate(params.untilDate)) {
            this.title += this.translate.instant("to") + " " + params.untilDate;
            newFilter.untilDate = formatDate(moment(params.untilDate, 'DD/MM/YYYY').toDate(), "d.MM.yyyy", 'nb-NO');
          }
        } else {
          this.title = null;
          newFilter.categories = [];
        }
        if (this.isVMFest) {
          newFilter.onlyVMFest = true;
          newFilter.municipality = this.municipalityControl.value;
        }
        this.filter = newFilter;
        //Load events
        this.loadEvents();
      }
    );
  }

  initializeDateFilter(): any {
    if (this.translate.currentLang != 'nb') {
      this.adapter.setLocale('en-GB');
    } else {
      this.adapter.setLocale(this.translate.currentLang);
    }
  }

  setupDateFilter() {
    this.dateFilterSubs = this.dateRangeForm.valueChanges.pipe(debounceTime(500)).subscribe(
      (newDates) => {
        let fromDate = '';
        let toDate = '';
        let category = this.translate.instant("Events");
        if (newDates.fromDate) {
          this.filter.fromDate = newDates.fromDate.format('DD/MM/YYYY');
          fromDate = this.translate.currentLang == 'nb' ? newDates.fromDate.format('d.MM.yyyy') : newDates.fromDate.format('d/MM/yyyy');
        } else {
          this.filter.fromDate = null;
        }
        if (newDates.untilDate) {
          this.filter.untilDate = newDates.untilDate.format('DD/MM/YYYY');
          toDate = this.translate.currentLang == 'nb' ? newDates.untilDate.format('d.MM.yyyy') : newDates.untilDate.format('d/MM/yyyy');
        } else {
          this.filter.untilDate = null;
        }
        if (!this.filter.categories || this.filter.categories?.length == 0) {
          this.filter.onlyFeatured = false;
          this.filter.onlyFeaturedVMFest = false;
        } else {
          category = this.categoryService.getCategoryName(this.filter.categories[0]) + " " + this.translate.instant("category");
        }
        this.title = category + " " + this.translate.instant("from") + " " + fromDate + " " + this.translate.instant("to") + " " + toDate;
        this.router.navigate([this.isVMFest ? '/vm-fest/category' : '/category', this.filter.categories?.length > 0 ? this.categoryService.getCategorySlug(this.filter.categories[0]) : 'all-events', { fromDate: this.filter.fromDate, untilDate: this.filter.untilDate }], { fragment: "top", replaceUrl: true });
        //Load events
        this.loadEvents();
      }
    )
  }

  setupMunicipalityFilter() {
    if (this.isVMFest) {
      this.municipalitySubscription = this.municipalityControl.valueChanges.subscribe(
        (newMunicipality: string | null) => {
          this.filter.municipality = newMunicipality || null;          
          //Load events
          this.loadEvents();
        }
      )
    }
  }

  initListSubscription() {
    if (!this.eventListSubs) {
      this.eventListSubs = this.eventListService.listChanges().subscribe(
        (list) => {
          this.eventList = list;
          this.totalHits = this.eventListService.getTotalHits();
          this.loading = false;

          if (isPlatformServer(this.platformId)) {
            this.state.set<Event[]>(EVENT_LIST, this.eventList);
          }
          if (this.eventList == null) {
            this.eventList = this.state.get<Event[]>(EVENT_LIST, []);
          }
        }
      );
    }
  }


  initLoadingSubscription() {
    this.eventListService.listIsLoading().subscribe(
      listIsLoading => this.loading = listIsLoading
    );
  }

  initLoadMoreIsLoadingSubscription() {
    this.eventListService.loadMoreIsLoading().subscribe(
      loadMoreIsLoading => this.loadMoreIsLoading = loadMoreIsLoading
    );
  }

  moreEventsAvailable() {
    return this.eventListService.moreEventsAvailable();
  }

  loadMoreEvents() {
    return this.eventListService.loadMoreEvents();
  }

  loadEvents() {
    this.eventListService.filterEvents(this.filter);
  }

  lastEventIsRendered() {
    if (isPlatformBrowser(this.platformId)) {
      if (window != undefined
        && this.scrollService.getPreviousUrl()?.indexOf('/event/') != -1
        && this.moveToPreviousScrollPosition) {
        window.scrollTo({ left: 0, top: this.scrollService.getLastPositionForEventList(), behavior: 'smooth' });
        this.moveToPreviousScrollPosition = false;
      }
    }
  }

  parseDate(param: string): string | null {
    if (param != '' && param != "null" && moment(param, 'DD/MM/YYYY').isValid()) {
      return param;
    }
    return null;
  }
}
