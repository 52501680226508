import { environment } from '../../environments/environment';

export class Filter {
  activityTypes?: string[] | null = null;
  categories?: string[] | null = null;
  eventParent?: string | null = null;
  eventTitleNb?: string | null = null;
  eventType?: string | null = null;
  fromDate?: string | null = null;
  municipality?: string | null = null;
  mode?: string | null = null;
  organizerSlug?: string | null = null;
  onlyFeatured?: boolean = false;
  onlyFeaturedVMFest?: boolean = false;
  onlyVMFest?: boolean = false;
  searchTerm?: string | null = null;
  sortBy?: string | null = environment.ui.showOnFilter.sortBy;
  superEvent?: string | null = null;
  tag?: string | null = null;
  untilDate?: string | null = null;
  venueSlug?: string | null = null;

  isEqual(aFilter: Filter): boolean {
    return Filter.equalArrays(this.categories, aFilter.categories)
      && Filter.equalArrays(this.activityTypes, aFilter.activityTypes)
      && this.searchTerm == aFilter.searchTerm
      && this.fromDate == aFilter.fromDate
      && this.untilDate == aFilter.untilDate
      && this.venueSlug == aFilter.venueSlug
      && this.organizerSlug == aFilter.organizerSlug
      && this.superEvent == aFilter.superEvent
      && this.eventTitleNb == aFilter.eventTitleNb
      && this.eventType == aFilter.eventType
      && this.mode == aFilter.mode
      && this.onlyFeatured == aFilter.onlyFeatured
      && this.onlyFeaturedVMFest == aFilter.onlyFeaturedVMFest
      && this.onlyVMFest == aFilter.onlyVMFest
      && this.sortBy == aFilter.sortBy
      && this.municipality == aFilter.municipality
      && this.tag == aFilter.tag;
  }

  public clone(): Filter {
    return Filter.fromObject(this);
  }

  public static fromObject(obj: any): Filter {
    let cloneObj: Filter = new Filter();
    for (let attribute of Object.keys(obj)) {
      if (Array.isArray(obj[attribute])) {
        cloneObj[attribute] = obj[attribute].slice(0);
      } else {
        cloneObj[attribute] = obj[attribute];
      }
    }
    return cloneObj;
  }

  public static equalFilterOptions(opt1, opt2) {
    if (Array.isArray(opt1) || Array.isArray(opt2)) {
      return this.equalArrays(opt1, opt2);
    }
    return opt1 == opt2;
  }

  private static equalArrays(cat1: string[] | null, cat2: string[] | null): boolean {
    if ((cat2 == null || cat2.length == 0) && (cat1 == null || cat1.length == 0)) {
      return true;
    }
    return Filter.eqSet(new Set(cat1), new Set(cat2));
  }

  private static eqSet(as, bs) {
    if (as.size !== bs.size) {
      return false;
    }
    for (let a of as) {
      if (!bs.has(a)) {
        return false
      }
    }
    return true;
  }
}
